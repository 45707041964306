
const getDataFormValue = function (dataForm) {
  const result = {}
  dataForm.forEach(inputsGroup => {
    if (Array.isArray(inputsGroup)) inputsGroup.forEach(input => { if (input.value !== undefined) { result[input.name] = input.value } })
    else if (inputsGroup.value !== undefined && inputsGroup.name !== undefined) {
      if (inputsGroup.type === '2fa') result[inputsGroup.name] = inputsGroup.value.join('')
      else result[inputsGroup.name] = inputsGroup.value
    }
  })
  return result
}

const getInputByName = function (dataForm, name) {
  for (const inputsGroup of dataForm) {
    if (Array.isArray(inputsGroup)) {
      for (const input of inputsGroup) {
        if (input.name === name) return input
      }
    } else if (inputsGroup.name === name) {
      return inputsGroup
    }
  }
}

const sendRequest = async function (form) {
  let result
  const request = await fetch(form.action, {
    method: form.method,
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    }),
    mode: 'cors',
    cache: 'default',
    credentials: 'same-origin',
    body: new URLSearchParams(getDataFormValue(form.inputs))
  })

  try {
    result = await request.json()
  } catch (e) { result = {} }

  return { ...result, ...{ redirected: request.redirected, url: request.url, status: request.status } }
}

const getCsrfToken = function () {
  return window.document.querySelector('meta[name="csrf-token"]') ? window.document.querySelector('meta[name="csrf-token"]').getAttribute('content') : ''
}

/* text_efx.vue */
class TextEffect {
  constructor (element, param) {
    this.param = {
      time: param !== undefined && param.time !== undefined ? param.time : [4000, 4000, 8000],
      delayDefault: param !== undefined && param.delayDefault !== undefined ? param.delayDefault : 4000,
      delayStart: param !== undefined && param.delayStart !== undefined ? param.delayStart : 0
    }
    this.paramShow = param !== undefined && param.paramShow !== undefined ? param.paramShow : [0, 0, 0]
    this.element = typeof (element) === 'string' ? document.querySelector(element) : element
    this.listAnim = this.element.querySelectorAll('.anim_title')
    this.timerShow = null
    this._play = false
    this.timers = {}
    this.scrollActivate = param !== undefined && param.scrollActivate !== undefined ? param.scrollActivate : true
    this.scrollPosition = this.element.getBoundingClientRect()
    this.scrollMaxTop = this.scrollPosition.top + this.scrollPosition.height + window.scrollY
    /**/
    this.init()
    setTimeout(function () { this.play() }.bind(this), this.param.delayStart)
  }

  init () {
    /* INIT TIMERS */
    for (let i = 0; i < this.listAnim.length; i++) {
      this.paramShow[i] = this.paramShow[i] === undefined ? 0 : this.paramShow[i]
      const key = this.param.time[i] === undefined ? this.param.delayDefault : this.param.time[i]
      if (this.timers[key] === undefined) {
        this.timers[key] = {
          timer: null,
          position: [i],
          delay: key,
          max: [this.listAnim[i].children.length]
        }
      } else {
        this.timers[key].max.push(this.listAnim[i].children.length)
        this.timers[key].position.push(i)
      }
    }

    /* INIT ELEMENTS */
    for (let k = 0; k < this.listAnim.length; k++) {
      const childrens = this.listAnim[k].children
      for (let j = 0; j < childrens.length; j++) {
        childrens[j].setAttribute('data-id', j)
      }
    }

    /* SCROLL */
    if (this.scrollActivate === true) {
      window.addEventListener('scroll', function () {
        if (window.scrollY > this.scrollMaxTop && this._play === true) this.pause()
        else if (window.scrollY <= this.scrollMaxTop && this._play === false) this.play()
      }.bind(this))
    }
  }

  /* ARIA LABEL */
  getAriaLabel () {
    let ariaLabel = ''
    const list = this.element.querySelectorAll('span > span')
    for (let i = 0; i < list.length; i++) {
      ariaLabel += list[i].classList.contains('anim_title') === true ? list[i].querySelector('._show').innerHTML : (list[i].children[1] ? list[i].children[0].innerHTML + list[i].querySelector('._show').innerHTML : list[i].innerHTML)
    }
    return ariaLabel
  }

  setAriaLabel () {
    const ariaLabel = this.getAriaLabel()
    this.element.parentNode.setAttribute('aria-label', ariaLabel.replace('<br>', ', '))
  }

  /* SHOW */
  show () {
    for (let i = 0; i < this.listAnim.length; i++) {
      /* remove ._show */
      const els = this.listAnim[i].querySelectorAll('._show')
      for (let j = 0; j < els.length; j++) {
        els[j].classList.remove('_show')
      }
      /* add ._show */
      const tempId = this.paramShow[i] === undefined ? 0 : parseInt(this.paramShow[i])
      this.listAnim[i].style.width = (this.listAnim[i].querySelector('[data-id="' + tempId + '"]').offsetWidth + 1) + 'px'
      this.listAnim[i].querySelector('[data-id="' + tempId + '"]').classList.add('_show')
      setTimeout(function (i) { this.listAnim[i].appendChild(this.listAnim[i].children[0]) }.bind(this), 2000, i)
      clearTimeout(this.timer_setAriaLabel)
      this.timer_setAriaLabel = setTimeout(this.setAriaLabel.bind(this), 50)
    }
  }

  /* LOOP */
  loop (param) {
    /* get next step */
    for (let i = 0; i < param.position.length; i++) {
      this.paramShow[param.position[i]] = (this.paramShow[param.position[i]] + 1) % param.max[i]
    }
    /* show */
    clearTimeout(this.timerShow)
    this.timerShow = setTimeout(this.show.bind(this), 50)
    /* loop */
    clearTimeout(param.timer)
    param.timer = setTimeout(this.loop.bind(this), param.delay, param)
  }

  /* PLAY */
  play () {
    for (const k in this.timers) {
      this.loop(this.timers[k])
    }
    this._play = true
  }

  /* PAUSE */
  pause () {
    for (const k in this.timers) {
      clearTimeout(this.timers[k].timer)
    }
    this._play = false
  }
}

export {
  TextEffect,
  sendRequest,
  getCsrfToken,
  getInputByName,
  getDataFormValue
}
